import { graphql, Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/services.scss";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import CommonSection from "../components/CommonSection";
import StatColumn from "../components/StatColumn";
import AccordionSection from "../components/AccordionSection";
import CaseStudy from "../sections/homepage/CaseStudy";
import Testimonials from "../sections/homepage/Testimonials";
import ButtonBanner from "../components/ButtonBanner";
import ClientLogoBanner from "../components/ClientLogoBanner";

const ProductConfigurator = ({ data }) => {
  const conversions = [
    {
      image: "../../images/3dconfigurator/3d-visualization-software.png",
      imageWebp: "../../images/3dconfigurator/3d-visualization-software.webp",
      title: "Sales presentations",
      sub: "Use the online product configuator for enterprise sales use case or bulk sales in real time. Generate instant bill of materials and invoices.",
    },
    {
      image: "../../images/3dconfigurator/3d-visualization-software.png",
      imageWebp: "../../images/3dconfigurator/3d-visualization-software.webp",
      title: "Product demo",
      sub: "Want to showcase your product and need a working model? Use online product configurator to set you up in minutes.",
    },
    {
      image: "../../images/3dconfigurator/3d-visualization-software.png",
      imageWebp: "../../images/3dconfigurator/3d-visualization-software.webp",
      title: "New Product or customisation",
      sub: "Train the internal sales team about the product nuances & features interactively.",
    },
    {
      image: "../../images/3dconfigurator/3d-visualization-software.png",
      imageWebp: "../../images/3dconfigurator/3d-visualization-software.webp",
      title: "Retail website",
      sub: "Just embed the product configurator on your website and empower users to quickly create, visualise and buy the products of their choices",
    },
  ];

  let withProductConfig = [
    {
      image: "../../images/3dconfigurator/product-configurator-software.png",
      imageWebp:
        "../../images/3dconfigurator/product-configurator-software.webp",
      title: "Showcase or create all product configurations",
      sub: "",
    },
    {
      image: "../../images/3dconfigurator/product-configurator-software.png",
      imageWebp:
        "../../images/3dconfigurator/product-configurator-software.webp",
      title: "Generate product mockups",
      sub: "",
    },
    {
      image: "../../images/3dconfigurator/product-configurator-software.png",
      imageWebp:
        "../../images/3dconfigurator/product-configurator-software.webp",
      title: "Generate bills of materials (BOMs) instantly",
      sub: "",
    },
    {
      image: "../../images/3dconfigurator/product-configurator-software.png",
      imageWebp:
        "../../images/3dconfigurator/product-configurator-software.webp",
      title: "Generate & share quotes/invoices in realtime",
      sub: "",
    },
  ];

  const enterprise = [
    {
      image: "../../images/3dconfigurator/augmented-reality-examples.png",
      imageWebp: "../../images/3dconfigurator/augmented-reality-examples.webp",
      title: "Personalized selling and catalog solution",
      sub: "",
    },
    {
      image: "../../images/3dconfigurator/augmented-reality-examples.png",
      imageWebp: "../../images/3dconfigurator/augmented-reality-examples.webp",
      title: "Shorter sales cycle",
      sub: "",
    },
    {
      image: "../../images/3dconfigurator/augmented-reality-examples.png",
      imageWebp: "../../images/3dconfigurator/augmented-reality-examples.webp",
      title: "Delightful product demos",
      sub: "",
    },
    {
      image: "../../images/3dconfigurator/augmented-reality-examples.png",
      imageWebp: "../../images/3dconfigurator/augmented-reality-examples.webp",
      title: "Better inter-departmental communication",
      sub: "",
    },
  ];

  let advantages = [
    {
      title: "Easy integration",
      sub: "HelloAR product configurator integrates seamlessly within your tech built in minutes.",
      image: "../../images/3dconfigurator/3d-augmented-reality.png",
      imageWebp: "../../images/3dconfigurator/3d-augmented-reality.webp",
    },
    {
      title: "Fetches data from your CRM or ERP",
      sub: "Instantly create bills of material (BOMs) from your system.",
      image: "../../images/3dconfigurator/3d-augmented-reality.png",
      imageWebp: "../../images/3dconfigurator/3d-augmented-reality.webp",
    },
    {
      title: "Device agnostic",
      sub: "Responsive configurator, optimized for all browsers and devices.",
      image: "../../images/3dconfigurator/3d-augmented-reality.png",
      imageWebp: "../../images/3dconfigurator/3d-augmented-reality.webp",
    },
    {
      title: "Faster time to market",
      sub: "Find orders for your new product or feature in seconds.",
      image: "../../images/3dconfigurator/3d-augmented-reality.png",
      imageWebp: "../../images/3dconfigurator/3d-augmented-reality.webp",
    },
  ];
  return (
    <Layout>
      <Helmet>
        <title>
          HelloAR: Product Configurator Software| Product Configurator
        </title>
        <meta
          name="description"
          content="Using HelloAR's product configurator software you can display all the product options you have in realtime and within a click"
        />
        <meta
          name="keywords"
          content="product configurator, product configurator software"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta
          property="og:image"
          content="../../videos/thumbnail/configurator.jpg"
        />
      </Helmet>

      <Container fluid className="services">
        <div className="spacer"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <CommonSection
          title="Bridge the gap between real & virtual-world"
          sub="Increase conversions with interactive & personalized digital catalogs. Let your customers understand every nuance of the product virtually."
          buttonText="REQUEST A DEMO"
        >
          <VideoPlayer
            videoUrl="../../../videos/product-configurator.mp4"
            videoThumbnail="../../images/thumbnail/product-configurator.webp"
          />
        </CommonSection>
        <div className="spacer-sm"></div>
        <ClientLogoBanner />
        <div className="spacer"></div>
        <AccordionSection
          items={withProductConfig}
          title="With product configurator you can"
          mainIndex={3}
        />
        <div className="spacer"></div>
        {/* <CaseStudy /> */}
        <div className="spacer"></div>
        <div className="container p-0">
          <AccordionSection
            items={conversions}
            title="How can product configurator increase sales and conversions?"
            mainIndex={3}
          />
        </div>
        <div className="spacer"></div>
        <ButtonBanner buttonTitle="REQUEST A DEMO" buttonUrl="/contact-us">
          <h1 className="h1 text-center text-light">
            One stop solution.
            <br />
            Zero upfront cost. Pay as you go.
          </h1>
        </ButtonBanner>
        <div className="spacer"></div>
        <StatColumn
          title="Retailers with product configurators have"
          items={[
            {
              stat: "250%",
              info: "Upto 250% higher conversions",
            },
            {
              stat: "11x",
              info: "11x more likrly to purchase",
            },
            {
              stat: "35%",
              info: "Upto 35% fewer returns",
            },
          ]}
          noFlex
        />
        <div className="spacer"></div>
        <div className="spacer-sm"></div>
        <AccordionSection
          items={enterprise}
          title="Enterprise sellers with product configurators have"
          mainIndex={3}
        />
        <div className="spacer"></div>
        <div className="spacer-sm"></div>
        <AccordionSection
          title="HelloAR product configurator advantages"
          items={advantages}
          mainIndex={3}
          inverted={true}
        />

        <div className="spacer"></div>
        <div className="spacer-sm"></div>

        <Testimonials />
      </Container>
    </Layout>
  );
};

// export const imgs = graphql`
//   query {
//     imageOne: file(relativePath: { eq: "about/1.png" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;

export default ProductConfigurator;
